.footer-cover {
  $url: 'https://images.unsplash.com/photo-1711990897167-303b30500d61?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';

  background-image: url($url);
  background-position: center;
  background-size: cover;
  @apply flex flex-col h-screen;
}
/* Scrollbar Track */
.slim-scroll::-webkit-scrollbar {
  width: 2px; /* width of the scrollbar */
}

/* Handle */
.slim-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px; /* radius of the scrollbar handle */
  @apply bg-default-100;
}

.slim-scroll::-webkit-scrollbar-thumb:hover {
  @apply bg-default-100;
}
